
import Vue from "vue";
import Component from "vue-class-component";
import daily from "@daily-co/daily-js";
import { Auth } from '@/store/modules';
import { User } from "@/store/auth";

@Component({ name: "MeetingView" })
export default class extends Vue {
  seat: {
    id: string;
    meeting: {
      name: string;
      url: string;
      date: Date;
      length: number;
    };
    userId: string;
    classId: string;
    status: string;
    meetingToken?: string;
  } | null = null;
  class: any = {};
  error = '';
  interval: any = null;
  timeLeft = '';
  leftMiliseconds = 0;
  loading = true;
  joinAnyway = false;
  joining = false;
  finished = false;

  get started() {
    return this.seat && (new Date(this.seat.meeting.date).valueOf() < Date.now())
  }

  get ended() {
    return this.seat && (new Date(this.seat.meeting.date).valueOf() + 15 * 60 * 1000 + this.seat.meeting.length * 60 * 1000 < Date.now())
  }

  get isOffline() {
    return this.seat && !this.seat.meeting.url;
  }

  get exitUrl() {
    if ((Auth.user as User).teacherId === this.class.teacherId) {
      return '/profile?tab=teacher'
    }

    if (!this.started || !this.seat) {
      return '/profile?tab=student'
    }

    return `/profile?tab=student&rate=${this.seat.classId}`;
  }

  refreshTimeLeft() {
    if (!this.seat) {
      return;
    }

    const left = (new Date(this.seat.meeting.date).valueOf()) - Date.now();
    this.leftMiliseconds = left;

    if (left < 1000) {
      this.initIframe();
    }

    const leftSeconds = Math.round(left / 1000)
    const leftMinutes = Math.floor(leftSeconds / 60);
    const leftHours = Math.floor(leftMinutes / 60);
    const leftDays = Math.floor(leftHours / 24);

    const days = leftDays ? `${leftDays} d ` : '';
    const hours = leftHours ? `${leftHours - leftDays * 24} h ` : '';
    const minutes = leftMinutes ? `${leftMinutes - leftHours * 60} min ` : '';
    const seconds = leftSeconds - leftMinutes * 60;

    this.timeLeft = `${days}${hours}${minutes}${seconds} s`;
  }

  refreshTimeToFinish() {
    if (!this.seat) {
      return;
    }

    const left = (new Date(this.seat.meeting.date).valueOf() + 15 * 60 * 1000 + this.seat.meeting.length * 60 * 1000) - Date.now();

    if (left < 0) {
      this.finished = true;
      clearInterval(this.interval);
    }
  }

  async initIframe() {
    clearInterval(this.interval);
    this.loading = true;
    this.joining = true;
    try {
      const frame = daily.wrap(document.getElementById("dailyiframe") as any, {
        url: this.seat?.meeting.url,
        token:
          this.seat?.meetingToken,
        showLeaveButton: true,
        showFullscreenButton: true,
      });
      frame.on('left-meeting', () => {
        this.$router.push(this.exitUrl);
      });
      await frame.join({
        url: this.seat?.meeting.url,
        token:
          this.seat?.meetingToken,
      });

      // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
      Auth.setMeetingEndDate(new Date(new Date(this.seat!.meeting.date).valueOf() + this.seat!.meeting.length * 60 * 1000 + 15 * 60 * 1000));
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.refreshTimeToFinish();
      }, 500);
    } catch (err) {
      this.error = 'Failed to initialize meeting.';
    }
    this.loading = false;
  }

  async mounted() {
    Auth.setMeetingEndDate(null);
    this.loading = true;
    try {
      const res = await Vue.$axios.get(`/meetings/${this.$route.params.id}/join`);
      this.seat = res.data;
      const response = await Vue.$axios.get(`/classes/${this.seat?.classId}`);
      this.class = response.data;
  
      if (this.ended) {
        this.loading = false;
        return;
      }

      if (this.started) {
        await this.initIframe()
      } else {
        this.interval = setInterval(() => {
          this.refreshTimeLeft();
  
          if (this.started) {
            this.initIframe();
            clearInterval(this.interval as any);
            this.interval = setInterval(() => {
              if (Date.now() > new Date(Auth.meetingEndDate as Date).valueOf()) {
                clearInterval(this.interval);
                window.location.reload();
              }
            }, 100);
          }
        }, 100);
      }
    } catch (err) {
      this.error = 'Failed to get information about the Class.';
    }
    this.loading = false;
  }
}
